<template>
  <section id="about" class="about">
    <div class="section-name">
      <h2 class="about-me">Cześć<span class="colorDot"></span></h2>
    </div>
    <div class="text-about-me">
      <img class="code" src="../assets/images/pc.png" alt="laptop" /><span
        >Jestem fullstack developerem. Pierwsze swoje kroki w programowaniu stawiałem pisząc kod Javy z użyciem Spring Frameworka. Następnie zająłem się frontendem. Ukończyłem kursy takie jak
        <span class="italic">"Web developer od podstaw"</span>,
        <span class="italic">"Frontend zaawansowany"</span>,
        <span class="italic">"Programowanie w Javascript"</span>,
        <span class="italic">"Kurs ASP.NET Core REST Web API"</span>,
        <span class="italic">kurs Javy oraz
        kurs Spring Framework.</span> Pomimo tego że najwięcej czasu spędziłem we frontendzie, inne obszary programowanie nie są mi obce.
        Świetnie odnajduję się w backendzie. Nie sprawia mi problemów
        praca z bazami danych. Od ponad roku pracuję komercyjnie na FE z wykorzystaniem Vue.js w połączeniu z Typescriptem oraz na BE korzystając z C#. Mogę stwierdzić, że w tym obszarze czuję się bardzo dobrze. Zachęcam do przejrzenia mojego portfolio.
      </span>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: "AboutComp",
  mounted: function () {
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      const gs = gsap.timeline({
        scrollTrigger: {
          trigger: ".about",
          start: "65% bottom",
        },
      });
      gs.fromTo(
        ".about",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 1.2,
        }
      ),
        gs.fromTo(
          ".code",
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1.2,
            delay:-.8
          }
        );
      return () => {
        // gs.scrollTrigger.kill();
      };
    },
  },
};
</script>
<style>
.about {
  border-top: none;
}
.code {
  margin-bottom: 10px;
  width: 50%;
  font-size: 10vw;
  float: right;
  shape-outside: polygon(
    15.53% 2.36%,
    83.83% 2.25%,
    84.16% 80.2%,
    99.54% 107.1%,
    49.3% 106.85%,
    -9.48% 114.04%,
    15.35% 74.41%
  );
}
.text-about-me span {
  font-size: 26px;
}
@media (max-width: 1100px) and (orientation: portrait) {
  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .text-about-me {
    margin: 0 15px;
  }

  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}
@media (max-width: 1200px) and (orientation: landscape) {
  .text-about-me {
    margin: 0 15px;
  }

  .text-about-me span {
    font-size: 18px;
  }

  .code {
    width: 50%;
  }
}
</style>
