<template>
  <div class="parallax">
    <img class="sky" id="sky" src="../assets/images/sky.png" alt="niebo" />
    <img
      class="mountain"
      id="mountain"
      src="../assets/images/mountain.png"
      alt="góra"
    />
    <img class="lake" id="lake" src="../assets/images/lake.png" alt="jezioro" />
    <img class="boat" id="boat" src="../assets/images/boat.png" alt="łódź" />
    <img
      class="forest"
      id="forest"
      src="../assets/images/forest.png"
      alt="las"
    />
  </div>
</template>
<script>
import { gsap } from "gsap";
export default {
  name: "ParallaxComp",
  mounted: function () {
    this.parallax();
    this.scrollAnimation();
  },
  methods: {
    parallax() {
      if (window.innerWidth > 1900) {
        let mountain = document.getElementById("mountain");
        let forest = document.getElementById("forest");
        let lake = document.getElementById("lake");
        let boat = document.getElementById("boat");
        if (window.scrollY < 700) {
          window.addEventListener("scroll", function () {
            let value = window.scrollY;

            if (window.scrollY < 700) {
              mountain.style.bottom = -150 + value * 0.3 + "px";
              lake.style.bottom = -37 + value * 0.01 + "%";
              forest.style.bottom = -170 + -value * 0.1 + "px";
              boat.style.right = 400 + value * 0.70 + "px";
              boat.style.bottom = value * 0.25 + "px";
            }
          });
        }
      }
    },
    scrollAnimation() {
      const gs = gsap.timeline({});
      gs.fromTo(
        ".parallax",
        { y: "10rem", opacity: 0 },
        {
          y: 0,
          opacity: 1,
          stagger: 0.2,
          duration: 1.5,
          delay: 3.6,
        }
      );
      return () => {
        // gs.scrollTrigger.kill();
      };
    },
  },
};
</script>

<style>
.parallax {
  height: 100vh;
  position: relative;
}
.parallax img {
  position: absolute;
  width: 100%;
  max-width: 2600px;
}

img.mountain {
  position: absolute;
  bottom: -150px;
  left: 50%;
  transform: translateX(-50%);
}

img.forest {
  position: absolute;
  bottom: -170px;
  left: 50%;
  transform: translateX(-50%);
}

img.sky {
  position: absolute;
  bottom: -400px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

img.lake {
  position: absolute;
  bottom: -37%;
  left: 50%;
  transform: translateX(-50%);
}

img.boat {
  position: absolute;
  bottom: 0;
  right: 400px;
  transform: translateX(-50%);
  width: 100px;
}

img.lighthouse {
  position: absolute;
  bottom: 24%;
  left: 60%;
  transform: translateX(-50%);
  width: 100px;
}
</style>
